import React, {useId, useRef, useState} from "react";
import styled from "styled-components";
import sendIcon from "../images/send.svg";
import SquaredButton from "./SquaredButton";

function ChatInput({onSubmit = (text) => {}}) {
    const id = useId();
    const [input, setInput] = useState('');
    const inputRef = useRef(null);
    const submitFunction = () => {
        console.log("Submitted")
        onSubmit(input);
        inputRef.current.value = ''
    };
    console.log(input)
    return (
    <InputContainer>
        <InputForm onSubmit={e => {
            e.preventDefault();
            submitFunction();
        }}>
            <InputWrapper>
                <AiIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc9ff3a9e0412c2739993be28f7c966003b54153254f6a965a07fd699179101d?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77" alt="AI Assistant Icon" />
                <label htmlFor={id}></label>
                <InputText ref={inputRef}
                           id={id}
                           placeholder="Ask AiDA"
                           onInput={e => setInput(e.target.value)}
                           onSubmit={e => {
                               e.preventDefault();
                               submitFunction();
                           }}
                />
            </InputWrapper>
            <SendIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/eec35f2d715de66427c8b32a3c576d4d0e7b8fea0cad017b979455629766eec8?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77" alt="Send message" />
        </InputForm>
        <SquaredButton
            src={sendIcon}
            alt="Send Button"
            color="#1640D6"
            opacity="0.2"
            size={"48px"}
            onSubmit={submitFunction}
        />
    </InputContainer>
  );
}
const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`
const InputForm = styled.form`
  border-radius: 16px;
  background-color: rgba(245, 246, 250, 0.04);
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  padding: 13px 22px;
    &:focus {
        outline: none;
    }
`;

const InputWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    &:focus {
        outline: none;
    }
`;

const AiIcon = styled.img`
  aspect-ratio: 1.04;
  object-fit: contain;
  object-position: center;
  width: 24px;
`;

const InputText = styled.input`
    background: transparent;
    width: 100%;
    border: none;
    color: #8a8e9e;
    font: 400 14px/1 Inter, sans-serif;
    &::placeholder {
        color: #8a8e9e;
    } 
    &:focus {
        outline: none;
    }
`;

const SendIcon = styled.img`
  aspect-ratio: 0.74;
  object-fit: contain;
  object-position: center;
  width: 14px;
  cursor: pointer;
`;

export default ChatInput;