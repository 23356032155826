import React from "react";
import styled from "styled-components";

function UserAvatar({ src, alt, size = "medium" }) {
  return <AvatarImage src={src} alt={alt} $size={size} />;
}

const AvatarImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: ${props => props.$size === "small" ? "44px" : "50px"};
  border-radius: 16px;
`;

export default UserAvatar;