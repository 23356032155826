import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import ChatInput from "../components/ChatInput";
import BorderButton from "../components/BorderButton";
import ArrowIcon from "../images/ArrowHead.svg"
import OptionsIcon from "../images/OptionDots.svg"
import ChatText from "../components/ChatText";
import {useLocation, useNavigate} from "react-router-dom";
import {ping, submitMessage} from "../proxy/ChatbotProxy";

const LoremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit," +
    " sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. " +
    "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." +
    " Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur." +
    " Excepteur sint occaecat cupidatat non proident," +
    " sunt in culpa qui officia deserunt mollit anim id est laborum.";
// TODO: Implement easing for text response
function ChatPage(){
    // Router hooks
    const location = useLocation();
    const navigate = useNavigate();

    // State setup

    let firstPrompt = location.state.prompt;
    const initMessages = [];
    if (firstPrompt)
    {
        initMessages.push({text: firstPrompt, isAgent: false});
        // Handle LLM Call here, for now:
    }

    const [messages, setMessages] = useState(initMessages);
    const [sessionId, setSessionId] = useState("");

    // State update functions
    const AddMessage = (messageObj) => {
        setMessages(prevMessages => [...prevMessages, messageObj])
    };
    const SubmitMessage = async (message) => {
        submitMessage(message, sessionId).then(response => {
            console.log(response)
            response.data["messages"].forEach((message) => {
                AddMessage({text: message, isAgent: true})
            });
            if (sessionId === "")
            {
                setSessionId(response.data["session_id"]);
            }
        }).catch(error => {AddMessage({text: "Error: " + error, isAgent: true})});
    }
    const GetInput = (text) => {
        AddMessage({text: text, isAgent: false});
        // Here should be the LLM Call
        SubmitMessage(text).then();
    }
    // First prompt handling
    useEffect( () => {
        if (firstPrompt) {
            SubmitMessage(firstPrompt).then();
        }
    }, []);
    // Scroll to bottom of chat
    const chatBottomRef = useRef(null);
    const scrollToBottom = () => {
        chatBottomRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <ChatPageContainer>
            <PageHeader>
                <BorderButton src={ArrowIcon}
                              alt={"Back"}
                              size={"30px"}
                              onSubmit={() => navigate('/')}/>
                <HeaderTitle>AiDA</HeaderTitle>
                <BorderButton src={OptionsIcon}
                              alt={"Options"}
                              size={"30px"}/>
            </PageHeader>
            <ChatSection>
                {
                    Array.from(messages).map((item, i) => (
                        <ChatText text={item.text} leftAligned={item.isAgent} key={i}/>
                    ))
                }
                <div ref={chatBottomRef}/>
            </ChatSection>
            <PageFooter>
                <ChatInput onSubmit={GetInput}/>
            </PageFooter>
        </ChatPageContainer>
    );
}
const ChatPageContainer = styled.main`
    display: flex;
    flex-direction: column;
    background-color: #000;
    height: 100vh;
    //overflow-y: auto;
`;

const PageHeader = styled.section`
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5% 5%;
`;

const HeaderTitle = styled.span`
    color: #fff;
    text-align: center;
    font: 400 24px/20px Inter, sans-serif;
    padding-top: 4%;
`;

const ChatSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    overflow-y: auto;
    margin: 0 2% 12px 2%;
    border-radius: 19px;
`

const PageFooter = styled.section`
    display: block;
    width: 100%;
    padding: 1% 2% 4% 2%;
    box-sizing: border-box;
`

export default ChatPage;