import react from 'react';
import styled from 'styled-components';
import Popup from "reactjs-popup";
import background from "../images/FullBackground.svg";
import SquaredButton from "./SquaredButton";
import optionDots from "../images/OptionDots.svg";
import ArrowRight from "../images/arrow-right.svg";
import WideButton from "./WideButton";
import {submitVideo} from "../proxy/ChatbotProxy";

export default function UploadDialog({open, children}) {
    const uploadRef = react.useRef(null);
    const handleUpload = () => {
        uploadRef.current.click();
    }
    const [uploadStatus, setUploadStatus] = react.useState("");

    const handleFileChange = (event) => {
        console.log(event.target.files[0]);
        submitVideo(event.target.files[0]).then(response => {setUploadStatus("Succeeded")}).catch( error => {setUploadStatus(error)});
    }

    return (
        <StyledPopup open={open} modal>
            <TopBar>
                <SquaredButton src={optionDots} alt={"Options"}/>
                <TitleText>Upload</TitleText>
                <SquaredButton src={ArrowRight} alt={"Arrow"}/>
            </TopBar>
            <DialogBody>
                <UploadSection>
                    { uploadStatus && <TitleText>{uploadStatus}</TitleText>}
                    <WideButton text={"Upload"} size={"100%"} onSubmit={handleUpload}/>
                    <input ref={uploadRef} type="file" onChange={handleFileChange} hidden/>
                </UploadSection>
            </DialogBody>
        </StyledPopup>
  );
}

const StyledPopup = styled(Popup)`
    &-content {
        margin: auto;
        background: url("${background}");
        width: 70%;
        height: 50%;
        padding: 5px;
        border-radius: 20px;
        box-shadow: 10px 10px 30px #15151C, -10px -10px 30px #15151C;
    }

    &-arrow {
        color: rgb(255, 255, 255);
    }

    [role='tooltip'] &-content {
        width: 200px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
    }

    &-overlay {
        background: rgba(0, 0, 0, 0.5);
    }

    [data-popup='tooltip'] &-overlay {
        background: transparent;
    }
`;

const DialogBody = styled.main`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    justify-content: center;
    align-items: center;
`;
const TopBar = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    gap: 10px;
`;
const TitleText = styled.span`
    font: 400 24px/32px Questrial, sans-serif;
    color: #fff;
`
const UploadSection = styled.section`
`
const StyledUploadButton = styled(WideButton)`
    margin-top: 10px;
`
const BottomBar = styled.section`

`

