import styled from "styled-components";
import Popup from "reactjs-popup";
import background from "../images/FullBackground.svg";
import SiteVisitForm from "../components/SiteVisitForm";

export default function UploadPage({open, onChange}) {
    return (
        <StyledPopup open={open} onClose={onChange} modal>
            <SiteVisitForm />
        </StyledPopup>
    )
}
const StyledPopup = styled(Popup)`
    &-content {
        margin: 12vh auto !important;
        background: url("${background}");
        width: 70%;
        height: 50%;
        padding: 5px;
        border-radius: 20px;
        box-shadow: 10px 10px 30px #15151C, -10px -10px 30px #15151C;
    }

    &-arrow {
        color: rgb(255, 255, 255);
    }

    [role='tooltip'] &-content {
        width: 200px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
    }

    &-overlay {
        background: rgba(0, 0, 0, 0.5);
        overflow-y:auto;
        height: 100vh;
    }

    [data-popup='tooltip'] &-overlay {
        background: transparent;
    }
`;