import React, {useEffect, useState} from "react";
import styled from "styled-components";
import addIcon from "../images/add.png";
import react from "react";

function ZoneTab({ zoneName, description, isActive, onChange }) {

    const uploadRef = react.useRef(null);
    const handleUpload = () => {
        uploadRef.current.click();
    }

    const [isAddHovered, setIsAddHovered] = useState(false);
    const [filelist, setFilelist] = useState([]);
    const addFile = (e) => {
        setFilelist(prevState => [...prevState, ...e.target.files]);
    }
    useEffect(() => {
        onChange(zoneName, description, filelist)
    }, [filelist]);
    console.log(filelist);
    return (
        <TabContainer $isActive={isActive}>
            <ZoneInfo>
                <ZoneName>{zoneName}</ZoneName>
                <ZoneDescription>{description}</ZoneDescription>
            </ZoneInfo>
            <AddContainer $isHovered={isAddHovered}
                          onClick={handleUpload}
                          onMouseEnter={() => setIsAddHovered(true)}
                          onMouseLeave={() => setIsAddHovered(false)}>
                <AddIcon src={addIcon} alt={`${zoneName} icon`} />
                <input type="file" ref={uploadRef} onChange={addFile} hidden/>
            </AddContainer>
        </TabContainer>
    );
}

const TabContainer = styled.div`
  border-radius: 18px;
  background-color: ${props => props.$isActive ? "rgba(245, 246, 250, 0.08)" : "rgba(245, 246, 250, 0.04)"};
  display: flex;
    flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 7px 14px;
  margin-top: 5px;
  cursor: pointer;
    box-sizing: border-box;
    font: 400 14px/20px Inter, sans-serif;
`;

const ZoneInfo = styled.div`
  display: flex;
  flex-direction: column;
    margin-top: 2px;
`;

const ZoneName = styled.h2`
  color: #fff;
  font-size: 14px;
  line-height: 1;
  margin: 0;
`;

const ZoneDescription = styled.p`
  color: #8a8e9e;
  font-size: 10px;
  line-height: 20px;
  margin: 0;
    padding-top: 2px;
`;

const AddContainer = styled.div`
    aspect-ratio: 1;
    //object-fit: contain;
    width: 38px;
    margin: 1% 0;
    border-radius: 100%;
    border-width: 3px;
    border-color: rgba(235, 236, 240, 0.1);
    border-style: solid;
    text-align: center;
    background: ${props => props.$isHovered ? "rgba(255, 255, 255, 0.1)" : "transparent"};
`;

const AddIcon = styled.img`
    aspect-ratio: 1;
    object-fit: contain;
    margin-top: calc(50% - 9.5px);
    margin-left: 1px; 
`

export default ZoneTab;