import React, {Fragment, useState} from "react";
import styled, {createGlobalStyle, ThemeProvider} from "styled-components";
import Markdown from "react-markdown";
import gfm from "remark-gfm";

function ChatText({text,
                   leftAligned=true}) {
    return (
        <BubbleContainer $leftAligned={leftAligned}>
            <Bubble $leftAligned={leftAligned}>
                <Fragment>
                    <GlobalStyle />
                    <Markdown remarkPlugins={[gfm]}>{text}</Markdown>
                </Fragment>
            </Bubble>
        </BubbleContainer>
    );
}

const BubbleContainer = styled.section`
    display: flex;
    flex-direction: row;
    font: 400 16px/20px Inter, sans-serif;
    width: 100%;
    color: #fff;
    justify-content: ${props => props.$leftAligned ? "left" : "right"};
`

const Bubble = styled.div`
    background-color: ${props => props.$leftAligned ? "rgba(255, 255, 255, 0.04)" : "rgba(255, 255, 255, 0.15)"};
    border-radius: 19px;
    width: 70%;
    padding: 3% 5%;
    //margin: ${props => props.$leftAligned ? "0 0 0 2%" : "0 2% 0 0"};
`

const GlobalStyle = createGlobalStyle`
    table {
        margin: 0 15% !important;
        border-spacing: 0 !important;
        border-collapse: collapse !important;
        border-color: inherit !important;
        display: block !important;
        width: 70% !important;
        max-width: 100% !important;
        overflow: auto !important;
        align-content: center !important;
    }
    td, th {
        border-color: inherit !important;
        border-style: solid !important;
        border-width: 2px !important;
        padding: 6px 13px !important;
    }
    tbody, tfoot, thead, tr {
        margin: 0 auto !important;
        border-color: inherit !important;
        border-style: solid !important;
        border-width: 2px !important;
        width: 100% !important;
    }
`



export default ChatText;