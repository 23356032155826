import React, {useState} from "react";
import styled from "styled-components";
import tinycolor from "tinycolor2";

function BorderButton({src,
                       alt,
                       size = "small",
                       radius = "100%",
                       onSubmit = () => {}}) {
    const [isHovered, setIsHovered] = useState(false);

    const color = "rgba(235, 236, 240, 0)"
    const varColor = tinycolor(color);
    varColor.setAlpha(!isHovered ? 0 : 0.5)
    console.log(varColor.toRgbString())
    return (
        <Button
            type={"submit"}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onSubmit={onSubmit}
            onClick={onSubmit}
            $color={varColor.toRgbString()}
            $size={ParseSize(size)}
            $radius={radius}>
            <ButtonImage src={src} alt={alt} />
        </Button>
    );
}

function ParseSize(size){
    if (size === "small" || size === "large")
    {
        return size === "small" ? "44px" : "50px"
    }
    return size;
}
const Button = styled.button`
    border-width: 2px;
    border-color: #fff;
    aspect-ratio: 1;
    background-color: ${props => props.$color};
    border-radius: ${props => props.$radius};
    width: ${props => props.$size};
    height: ${props => props.$size};
`
const ButtonImage = styled.img`
  aspect-ratio: 1;
  object-fit: fill;
  object-position: center;
    
`;

export default BorderButton;