import React from "react";
import styled from "styled-components";
import {CircularProgressbar, buildStyles} from "react-circular-progressbar";
import SquaredButton from "./SquaredButton";
import NotificationIcon from "../images/notification.svg"
import WideButton from "./WideButton";

function CircularProgressWidget({title,
                                subText,
                                progress=70,
                                maxProgress = 100,
                                valueFormat = ValueFunctions.percentage,
                                numNotifications=27,
                                minHeight="150px",
                                minWidth="194px"}) {
    return (
        <WidgetContainer $minHeight={minHeight} $minWidth={minWidth}>
            <HeaderContainer><HeaderText>{title}</HeaderText></HeaderContainer>
            <ProgressBarContainer >
                <ProgressBarDiv>
                    <CircularProgressbar value={progress}
                                         maxValue={maxProgress}
                                         text={valueFormat(progress, maxProgress)}
                                         strokeWidth={4}
                                         styles={buildStyles(CircularBarStyles(ColorFunction(progress, maxProgress)))}/>
                </ProgressBarDiv>
            </ProgressBarContainer>
            <SubTextSection>{subText}</SubTextSection>
            <BottomBar>
                <NotificationContainer>
                    <SquaredButton src={NotificationIcon} size="32px" radius="100%"/>
                    <NotificationNumber>{numNotifications}</NotificationNumber>
                </NotificationContainer>
                <ViewButtonContainer>
                <WideButton text="View" size="84px" color="rgba(235, 236, 240, 0.04)"/>
                </ViewButtonContainer>
            </BottomBar>
        </WidgetContainer>
    );
}

export const ValueFunctions = {
    percentage: (val, max) => {return `${val}%`},
    fraction: (val, max) => {return `${val}/${max}`},
    dollars: (val, max) => {return `$${val}`}
}

const ColorFunction = (progress, maxProgress) => {
    if ((progress/maxProgress) >= 0.5)
    {
        return "#00B2FF";
    }
    else
    {
        return "#ED5AB3";
    }
}

const CircularBarStyles = (valueColor) => {
    return {
        rotation: 0.5,
        pathColor: valueColor,
        textColor: '#fff',
        trailColor: 'rgba(235, 236, 240, 0.1)',
    }
}

const WidgetContainer = styled.main`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 26px;
    background-color: rgba(255, 255, 255, 0.1);
    min-height: ${props => props.$minHeight};
    min-width: ${props => props.$minWidth};
    width: 194px;
    aspect-ratio: 0.7;
    font: 400 15px/24px Inter, sans-serif;

`

const HeaderContainer = styled.section`
    font: 400 15px/24px Inter, sans-serif;
    text-align: left;
    padding: 10% 5% 0%;
    width: 90%;
`

const HeaderText = styled.span`
    color: #fff;
`

const ProgressBarContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
`

const ProgressBarDiv = styled.div`
    padding: 15% 20% 5% 20%;
`

const SubTextSection = styled.section`
    font-size: 12px;
    color: #8a8e9e;
    // min-height: 50px;
    width: 100%;
    padding: 3% 5%;
    text-align: left;
    box-sizing: border-box;
`
const BottomBar = styled.section`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 2% 0 7%;
`
const NotificationContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0% 2% 0%;
    gap: 6px;
`
const NotificationNumber = styled.span`
    color: #fff;
    padding: 5% 0 0% 0%;
    font-size: 13px;
`
const ViewButtonContainer = styled.div`
    display: block;
    padding-right: 5%;
`
export default CircularProgressWidget;
