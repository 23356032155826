export const zoneData = [
    {
        zoneName: "Zone 1",
        description: "Entrance",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: true
    },
    {
        zoneName: "Zone 2",
        description: "Corridor",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    },
    {
        zoneName: "Zone 3",
        description: "Cubicles",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    },
    {
        zoneName: "Zone 4",
        description: "Men's Toilet",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    },
    {
        zoneName: "Zone 5",
        description: "Women's Toilet",
        iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/4199e888f57752267e0f34f5814f04a8cd9589c0ea8e4ba72b8c12e294230350?placeholderIfAbsent=true&apiKey=209bece3c3f049b5a52a827e5a523b77",
        isActive: false
    }
];