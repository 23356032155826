import React from "react";
import styled from "styled-components";

function WideButton({  text,
                       className = "button",
                       size = "90px",
                       radius = "16px",
                       color = "#355BE1",
                       onSubmit = () => {}}) {
    return (
        <div className={className}>
            <Button
                   type={"submit"}
                   onSubmit={onSubmit}
                   onClick={onSubmit}
                   $color={color}
                   $size={size}
                   $radius={radius}>
            <ButtonText>{text}</ButtonText>
        </Button></div>
    );
}
const Button = styled.button`
    border: none;
    padding: 1px;
    margin-left: 7px;
    background-color: ${props => props.$color};
    border-radius: ${props => props.$radius};
    width: ${props => props.$size};
    height: auto;
    min-height: 34px;

`
const ButtonText = styled.span`
  object-fit: contain;
  justify-content: center;
    color: #fff;
    font: 400 12px/30px Inter, sans-serif;
`;



export default WideButton;