import React, {useState} from "react";
import styled from "styled-components";
import ZoneTab from "./ZoneTab";
import { zoneData } from "./zoneData";
import DateIcon from "../images/Date.png"
import {uploadZoneForm} from "../proxy/ChatbotProxy";

function SiteVisitForm() {

    const [formData, setFormData] = useState({
        comment: "",
        date: "",
        zones: []
    })
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const uploadFileHandler = (zone_id, description, files) => {
        setFormData((prevState) => {
            if (!prevState.zones.find((zone) => zone.id === zone_id)) {
                return {
                    ...prevState,
                    zones: [...prevState.zones, {id: zone_id, description: description, files: files}]
                }
            }
            return {
                ...prevState,
                zones: prevState.zones.map((zone) => {
                    if (zone.id === zone_id) {
                        return {...zone, description: description, files: files}
                    }
                    return zone
                })
            }
        })
    }

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        uploadZoneForm(formData).then((response) => {console.log(response)});
    }

    console.log(formData)
    return (
        <FormContainer>
            <FormContent onSubmit={e => e.preventDefault()}>
                <FormContentContainer>
                    <Header>
                        <Title>Add Site Visit</Title>
                        <CommentInput name="comment" placeholder="Tap to add comments..." onChange={handleInputChange}/>
                        <DateSelector>
                            <CalendarIcon src={DateIcon} alt="Calendar"/>
                            <DateInput name="date" aria-label="Date" type="date" onChange={handleInputChange}/>
                        </DateSelector>
                    </Header>
                    <Divider/>
                    <Main>
                        <ZoneList>
                            {zoneData.map((zone, index) => (
                                <ZoneTab key={index} {...zone} onChange={uploadFileHandler}/>
                            ))}
                            <ConfirmButton onSubmit={handleSubmit} onClick={handleSubmit}>Confirm</ConfirmButton>
                        </ZoneList>
                    </Main>
                </FormContentContainer>
            </FormContent>
        </FormContainer>
    );
}

const FormContainer = styled.div`
    border-radius: 30px;
    max-width: 100vw;
    font-family: Inter, sans-serif;
    font-weight: 400;
    margin: 0 auto;
`;

const FormContent = styled.form`
    border-radius: 30px;
    background-color: #15151c;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 5vw 0 10vw;
    border: 2px solid #282831;
    box-sizing: border-box;
    justify-content: center;

`;

const FormContentContainer = styled.div`
    box-sizing: border-box;
`;

const Header = styled.div`
    display: flex;
    margin: 0 4vw;
    width: auto;
    max-width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    //font-size: 2vw;
    color: #8a8e9e;
    line-height: 1;
    box-sizing: border-box;
`;

const Title = styled.h1`
    color: #fff;
    font-size: calc(18px + 1vw);
    font-weight: 500;
    line-height: 2;
`;

const CommentInput = styled.textarea`
    align-self: stretch;
    margin-top: 2vw;
    margin-right: 4vw;
    background: transparent;
    border: none;
    color: #8a8e9e;
    resize: none;
    font: 400 14px/20px Inter, sans-serif;
    width: 80%;
    box-sizing: border-box;

`;

const DateInput = styled.input`
    background: transparent;
    border: none;
    color: #8a8e9e;
    &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
`;

const DateSelector = styled.div`
    display: flex;
    margin-top: 4vw;
    gap: 2vw;
    align-items: center;
    cursor: pointer;
`;

const CalendarIcon = styled.img`
    aspect-ratio: 1;
    object-fit: contain;
    width: 5vw;
`;

const Divider = styled.hr`
    min-height: 1px;
    margin-top: 3vw;
    width: 100%;
    border: none;
    border-top: 1px dashed rgba(216, 218, 229, 0.16);
`;

const Main = styled.div`
    align-self: center;
    display: flex;
    margin-top: 4vw;
    margin-right: 3%;
    margin-left: 3%;
    width: auto;
    max-width: 90vw;
    gap: 3vw;
    box-sizing: border-box;

`;

const ZoneList = styled.div`
    //align-self: flex-start;
    display: flex;
    //height: 50vh;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
`;

const ConfirmButton = styled.button`
    align-self: stretch;
    border-radius: 18px;
    background-color: #1640d6;
    margin-top: 1vh;
    min-height: 4vh;
    width: 100%;
    font-size: calc(12px + 0.8vw);
    color: #fff;
    font-weight: 600;
    line-height: 2;
    border: none;
    cursor: pointer;
`;

const ScrollIndicator = styled.div`
    border-radius: 3px;
    background-color: #6e6c7a;
    width: 1vw;
    height: 60vh;
`;

export default SiteVisitForm;