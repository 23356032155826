import WelcomeSection from "../components/WelcomeSection";
import OverviewSection from "../components/OverviewSection";
import ReportSection from "../components/ReportSection";
import styled from "styled-components";
import UploadDialog from "../components/UploadDialog";
import WideButton from "../components/WideButton";
import {useState} from "react";
import background from "../images/FullBackground.svg";
import UploadPage from "./UploadPage";

function MainPage() {

    const [uploadOpen, setUploadOpen] = useState(false);

    return (
        <StyledMainPage className="App" $open={uploadOpen} >
            <WelcomeSection />
            <OverviewSection />
            <ReportSection />
            <StyledWideButton text={"Upload"} size={"100%"} onSubmit={() => setUploadOpen(prevState => !prevState)}/>
            <UploadPage open={uploadOpen} onChange={() => setUploadOpen(prevState => !prevState)}>
            </UploadPage>
        </StyledMainPage>
    );
}

const StyledMainPage = styled.div`
    background-image: url(${background});
    min-height : 100vh;
    width: 100vw;
    overflow-y: ${props => props.$open ? "hidden" : "auto"};
    overflow-x: hidden;
`;

const StyledWideButton = styled(WideButton)`
    margin-top: 15px;
    margin-right: 15px;
    box-sizing: border-box;
`;
export default MainPage;
