import React from "react";
import styled from "styled-components";
import WideButton from "./WideButton";

function ContentSection( {title, children} ) {
    return (
        <OverviewContainer>
            <HeaderContainer>
                <HeaderText>{title}</HeaderText>
                <WideButton text={"View All"}/>
            </HeaderContainer>
            <ContentDiv>
                {children}
            </ContentDiv>
        </OverviewContainer>
    );
}

const OverviewContainer = styled.main`
  display: flex;
  //max-width: 335px;
  flex-direction: column;
  @media (max-width: 640px) {
    margin: 0 auto;
  }
`;
const HeaderContainer = styled.section`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5% 5% 2% 5%;
`
const HeaderText = styled.span`
    font: 500 18px/28px Inter, sans-serif;
    color: #fff;
    vertical-align: center;
`

const ContentDiv = styled.div`
    box-sizing: border-box;
    padding: 2% 0% 2% 4%;
    display: block;
    overflow-y: hidden;
`

export default ContentSection;