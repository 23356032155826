import axios from "axios";

const process_url = "https://api.oblix.tech";
const chat_url = "https://api.oblix.tech"
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";

async function submitMessage(message, sessionId="") {
  return axios.post(chat_url + "/chat", { message: message, session_id: sessionId});
}

async function submitVideo(file) {
    let formData = new FormData();
    formData.append("file", file);
    return axios.post(process_url + "/input/image", formData, {
        headers: {
        'Content-Type': 'multipart/form-data'
        }
    }).then(response => {
      console.log(response);
    }).catch(error => {
      console.log(error);
    });
}

async function uploadZoneForm(formData) {
    let form = new FormData();
    form.append("comment", formData.comments);
    form.append("date", formData.date);
    form.append("project_id", "test_project")

    formData.zones.forEach((zone, index) => {
        form.append(`zones[${index}][zone_id]`, zone.id);
        form.append(`zones[${index}][description]`, zone.description);
        zone.files.forEach((file, fileIndex) => {
            form.append(`zones[${index}][files][${fileIndex}]`, file);
        });
    });

    try {
        const response = await axios.post(process_url + '/image/zone', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log(response.data);
    } catch (error) {
        console.error(error);
    }
}

async function ping() {
  let response = await axios.get("/ping");
  return response.data;
}

export { submitMessage, ping, submitVideo, uploadZoneForm };